"use client"

// import { Card, Title, Text, TextInput, Switch, Select, SelectItem } from '@tremor/react';
import { Key, ReactNode, useReducer, useState } from 'react';
import AutoComplete, { Address } from './AutoComplete';
import { ContextPromise, queryLtl, queryServiceNames } from '../util/query';
import { addPrefix, toUpperCase } from '../util/converter';
import { BarsArrowDownIcon } from '@heroicons/react/24/solid';
import { showError, unitDisplay } from '../util/utils';

type PackageDataItem = {
    [name: string]: any
}

type SpecialServicesItem = {
    "serviceCode": string
}

type QuoteReq = {
    "serviceName": string,
    "shipperAddress": string,
    "shipperCity": string,
    "shipperProvince": string,
    "shipperPostalCode": string,
    "shipperCountry": string,
    "consigneeAddress": string,
    "consigneeCity": string,
    "consigneeProvince": string,
    "consigneePostalCode": string,
    "consigneeCountry": string,
    "packageData": PackageDataItem[],
    "specialServices": SpecialServicesItem[]
}

enum QuoteActionType {
    SetShipFrom = 'SetShipFrom',
    SetShipTo = 'SetShipTo',
    SetPackageData = 'SetPackageData',
    SetPackageUnit = 'SetPackageUnit',
    SetHasBattery = 'SetHasBattery',
    SetBatteryDetail = 'SetBatteryDetail',
    SetSpecial = 'SetSpecial'
}

type QuoteAction = {
    type: QuoteActionType,
    payload: any
}



const quoteReducer = (quoteReq: QuoteReq, action: QuoteAction) => {
    switch (action.type) {
        case QuoteActionType.SetShipFrom:
            const shipFromAddressData = addPrefix('shipper', action.payload);
            quoteReq = { ...quoteReq, ...shipFromAddressData };
            break;
        case QuoteActionType.SetShipTo:
            const shipToAddressData = addPrefix('consignee', action.payload)
            quoteReq = { ...quoteReq, ...shipToAddressData };
            break;
        case QuoteActionType.SetPackageData:
            const { row, name, value }: any = { ...action.payload };
            quoteReq.packageData[row][name] = value;
            quoteReq = { ...quoteReq };
            break;
        case QuoteActionType.SetPackageUnit:
            const checked = action.payload.target.checked;
            quoteReq.packageData.forEach(p => {
                p.lengthUnit = checked ? 'cm' : 'in';
                p.weightUnit = checked ? 'kg' : 'lb';
                p.length = unitDisplay(p.length, p.lengthUnit);
                p.width = unitDisplay(p.width, p.lengthUnit);
                p.height = unitDisplay(p.height, p.lengthUnit);
                p.weight = unitDisplay(p.weight, p.weightUnit);
                quoteReq = { ...quoteReq };
            });
        break;
    }
    console.log(quoteReq);
    return { ...quoteReq };
}



const quoteBatteryReducer = (quoteBattery: any, action: QuoteAction) => {
    console.log(action);
    switch (action.type) {
        case QuoteActionType.SetHasBattery:
            quoteBattery.hasBattery = action.payload.target.checked;
            break;
        case QuoteActionType.SetBatteryDetail:
            const name = action.payload.target.name;
            const value = action.payload.target.value;
            quoteBattery.batteryDetails[name] = value;
            break;
    }
    console.log(quoteBattery);
    return { ...quoteBattery };
}

const quoteRequestTemplate = {
    "serviceName": "dayandross",
    "shipperAddress": "",
    "shipperCity": "",
    "shipperProvince": "",
    "shipperPostalCode": "",
    "shipperCountry": "",
    "consigneeAddress": "",
    "consigneeCity": "",
    "consigneeProvince": "",
    "consigneePostalCode": "",
    "consigneeCountry": "",
    "packageData": [
        {
            "length": 48,
            "width": 40,
            "height": 60,
            "weight": 400,
            "lengthUnit": "in",
            "weightUnit": "lb",
            "quantity": 1
        }
    ],
    "specialServices": []
};

const quoteBatteryTemplate = {
    "hasBattery": false,
    "batteryDetails": {
        "material": 'LITHIUM_ION',
        "packing": 'CONTAINED_IN_EQUIPMENT',
        "quantity": 1
    }
};

export default function QuoteLtlReqPage() {

    const [quoteReq, updateQuoteReq] = useReducer(quoteReducer, quoteRequestTemplate);
    const [quoteBattery, updateQuoteBattery] = useReducer(quoteBatteryReducer, quoteBatteryTemplate);
    const [quoteList, updateQuoteList] = useState<any>([]);

    const updatePack = (e: React.FormEvent<HTMLInputElement>) => {
        const row = e.currentTarget.dataset.row;
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        updateQuoteReq({ type: QuoteActionType.SetPackageData, payload: { row, name, value } });
    };

    const updateSpecialService = (e: React.FormEvent<HTMLInputElement>) => {
        const serviceCode = e.currentTarget.name;
        const selected = e.currentTarget.checked;
        if (selected) {
            // Add it
            quoteReq.specialServices.push({ serviceCode });
        } else {
            // Remove it
            quoteReq.specialServices = quoteReq.specialServices.filter(s => s.serviceCode != serviceCode);
        }
        updateQuoteReq({ type: QuoteActionType.SetSpecial, payload: quoteReq });
    };

    const genOnePackageUi = (pack: PackageDataItem, index: number): ReactNode => {
        return (
            <div className="grid sm:grid-flow-col sm:auto-cols-auto gap-2 min-h-10 rounded-lg shadow border-solid border-1" key={index}>

                <div className='grid gap-2 m-2'>
                    <label className="block tracking-wide text-gray-700 text-sm">Pallet Type *</label>
                    <select className="block w-full min-w-4 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                        {/* 
                        <option value="1">48&quot;x40&quot;</option>
                        <option value="2">48&quot;x48&quot;</option> 
                        */}
                        <option value="3">Custom</option>
                    </select>
                </div>
                <div className='grid gap-2 m-2'>
                <label className="block tracking-wide text-gray-700 text-sm">Length* ({ pack.lengthUnit })</label>
                    <input data-row={index} name="length" onChange={updatePack} type='number' value={pack.length} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
                </div>

                <div className='grid gap-2 m-2'>
                    <label className="block tracking-wide text-gray-700 text-sm">Width * ({ pack.lengthUnit })</label>
                    <input data-row={index} name="width" onChange={updatePack} type='number' value={pack.width} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
                </div>
                <div className='grid gap-2 m-2'>
                    <label className="block tracking-wide text-gray-700 text-sm">Height * ({ pack.lengthUnit })</label>
                    <input data-row={index} name="height" onChange={updatePack} type='number' value={pack.height} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
                </div>
                <div className='grid gap-2 m-2'>
                    <label className="block tracking-wide text-gray-700 text-sm">Weight * ({ pack.weightUnit })</label>
                    <input data-row={index} name="weight" onChange={updatePack} type='number' value={pack.weight} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
                </div>
                <div className='grid gap-2 m-2'>
                    <label className="block tracking-wide text-gray-700 text-sm">Quantity *</label>
                    <input data-row={index} name="quantity" onChange={updatePack} type='number' value={pack.quantity} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
                </div>
                <div className='grid gap-2 m-2'>
                    <label className="hidden sm:block tracking-wide text-gray-700 text-sm">&nbsp;</label>
                    {/* <TrashIcon className="h-8 w-8 stroke-red-600" /> */}
                </div>
            </div>);
    };

    const handleQuote = async () => {
        quoteList.length = 0;
        updateQuoteList([]);

        const serviceNamesResp = await queryServiceNames();
        const serviceNamesJson = await serviceNamesResp.json();
        if (!serviceNamesJson) {
            showError('No service names available.');
            return;
        }
        const serviceNames = serviceNamesJson.map((s: { account_base: string }) => s.account_base);
        const respList = serviceNames.map((s: string) => queryLtl({ quoteReq, quoteBattery, context: { serviceName: s } }));
        respList.forEach(async (resp: ContextPromise) => {
            const quote = await (await resp).json();
            quote.serviceName = resp.context.serviceName;
            quoteList.push(quote);
            updateQuoteList([...quoteList]);
        });
    };

    console.log('Render start', quoteReq, quoteBattery);
    return <div className="grid grid-cols-1 gap-9">
        <div>
            <div className="font-bold">Address Details</div>
            <div className="grid sm:grid-cols-2 gap-2 rounded-lg shadow border-solid border-1" >
                <div className='grid gap-2 m-2'>
                    <label className="block tracking-wide text-gray-700 text-sm">Ship From</label>
                    <AutoComplete inputId="addressFrom" onChanged={(address: Address) => {
                        updateQuoteReq({ type: QuoteActionType.SetShipFrom, payload: address })
                    }} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
                </div>
                <div className='grid gap-2 m-2'>
                    <label className="block tracking-wide text-gray-700 text-sm">Ship To</label>
                    <AutoComplete inputId="addressTo" onChanged={(address: Address) => {
                        updateQuoteReq({ type: QuoteActionType.SetShipTo, payload: address })
                    }} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
                </div>
            </div>
        </div>

        <div>
            <div className="flow-root">
                <div className="inline font-bold">Pallet Information</div>
                <div className="float-right ml-1">
                    Metric (cm&kg)
                </div>
                <div className="float-right">
                    <input type="checkbox" onChange={ event => updateQuoteReq({ type:QuoteActionType.SetPackageUnit, payload: event }) }/>
                </div>
            </div>

            {quoteReq.packageData.map((pack, i) => genOnePackageUi(pack, i))}

        </div>

        <div>
            <div className="font-bold">Shipping Options</div>
            <div className="grid gap-2 p-2 rounded-lg shadow border-solid border-1" >
                <div>
                    <input type="checkbox" name="hasBattery" onChange={event => updateQuoteBattery({ type: QuoteActionType.SetHasBattery, payload: event })} /> <label className="tracking-wide">Include lithium batteries (hazardous material)</label>
                    <div className="text-sm text-gray-700">This shipment contains dangerous goods or hazardous materials</div>
                    {quoteBattery.hasBattery && <div className="p-2">
                        <div>Battery Type</div>
                        <div className="flex" onChange={event => updateQuoteBattery({ type: QuoteActionType.SetBatteryDetail, payload: event })}>
                            <div className="flex items-center me-4">
                                <input id="inline-radio" type="radio" value="LITHIUM_ION" name="material" defaultChecked={quoteBattery.batteryDetails.material === 'LITHIUM_ION'} />
                                <label htmlFor="inline-radio" className="ms-2 text-sm">Lithium Ion</label>
                            </div>
                            <div className="flex items-center me-4">
                                <input id="inline-2-radio" type="radio" value="LITHIUM_METAL" name="material" defaultChecked={quoteBattery.batteryDetails.material === 'LITHIUM_METAL'} />
                                <label htmlFor="inline-2-radio" className="ms-2 text-sm">Lithium Metal</label>
                            </div>
                        </div>
                        <div className="mt-2">How is it packaged?</div>
                        <div className="flex" onChange={event => updateQuoteBattery({ type: QuoteActionType.SetBatteryDetail, payload: event })}>
                            <div className="flex items-center me-4">
                                <input id="inline-radio" type="radio" value="CONTAINED_IN_EQUIPMENT" name="packing" defaultChecked={quoteBattery.batteryDetails.packing === 'CONTAINED_IN_EQUIPMENT'} />
                                <label htmlFor="inline-radio" className="ms-2 text-sm">Contained or installed in equipment</label>
                            </div>
                            <div className="flex items-center me-4">
                                <input id="inline-2-radio" type="radio" value="PACKED_WITH_EQUIPMENT" name="packing" defaultChecked={quoteBattery.batteryDetails.packing === 'PACKED_WITH_EQUIPMENT'} />
                                <label htmlFor="inline-2-radio" className="ms-2 text-sm">Packaged separately with other equipment</label>
                            </div>
                        </div>
                        <div className="mt-2">Battery Quantity</div>
                        <input name="quantity" onChange={e => updateQuoteBattery({ type: QuoteActionType.SetBatteryDetail, payload: e })} type='number' value={1} className="appearance-none block w-32 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />

                    </div>}
                </div>

            </div>
        </div>

        <div>
            <div className="font-bold">Additional Service</div>
            <div className="grid gap-2 p-2 rounded-lg shadow border-solid border-1" >
                <div className='text-sm text-gray-700'>Additional Charges May Apply</div>
                <div className="grid sm:grid-cols-3">
                    <div className='inline-ga'>
                        <input type="checkbox" name="PRESPU" onChange={updateSpecialService} />
                        <label className="ml-2" htmlFor="PRESPU">Private Residence Pickup</label>
                    </div>
                    <div>
                        <input type="checkbox" name="TLGGP" onChange={updateSpecialService} />
                        <label className="ml-2" htmlFor="TLGGP">Tailgate Pickup</label>
                    </div>
                    <div>
                        <input type="checkbox" name="PROTEC" onChange={updateSpecialService} />
                        <label className="ml-2" htmlFor="PROTEC">Protected Service</label>
                    </div>
                    <div>
                        <input type="checkbox" name="PRESDL" onChange={updateSpecialService} />
                        <label className="ml-2" htmlFor="PRESDL">Private Residence Delivery</label>
                    </div>
                    <div>
                        <input type="checkbox" name="TLGDEL" onChange={updateSpecialService} />
                        <label className="ml-2" htmlFor="TLGDEL">Tailgate Delivery</label>
                    </div>
                    <div>
                        <input type="checkbox" name="APTFGT" onChange={updateSpecialService} />
                        <label className="ml-2" htmlFor="APTFGT">Appointment Delivery</label>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <button onClick={handleQuote} className="bg-green-700 hover:bg-green-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                Get Rates
            </button>
        </div>

        {
            quoteList.length > 0 && <div className="grid gap-2 p-2 rounded-lg shadow border-solid border-1" >
                {quoteList.map((row: any, i: Key) => {
                    if (!row.error)
                        // return (
                        //     <div className="grid sm:grid-cols-5 gap-2 p-2 border-solid border-2" key={i}>
                        //         <div className="">{row.serviceName}</div>
                        //         <div className="text-red-500 col-span-3">{row.error}</div>                                
                        //     </div>);
                        return (
                            <div className="grid sm:grid-cols-5 gap-2 p-2 border-solid border-2" key={i}>
                                <div className="flex h-10 items-center font-bold">{row.serviceName}</div>
                                <div className="col-span-3">
                                    <label>
                                        <input className="peer/showLabel absolute scale-0" type="checkbox" />
                                        <span className="block max-h-10 overflow-hidden rounded-lg transition-all duration-300 peer-checked/showLabel:max-h-52 p-2">
                                            <div className="flex h-10 cursor-pointer items-center font-bold">{row.currency}${row.shippingFee} <BarsArrowDownIcon className="h-4 ml-4" /></div>
                                            <div className="mb-2">
                                                {
                                                    row.shipmentCharges && row.shipmentCharges.map((charge: any, i: number) => <div key={i} className=" grid grid-cols-2">
                                                        <div>{charge.name}</div><div>${charge.value}</div>
                                                    </div>)
                                                }
                                            </div>
                                        </span>
                                    </label>
                                </div>
                                <div className="flex h-10 items-center font-bold">
                                    <button className="float-right rounded-md bg-green-600/50 px-4 py-px text-xs font-semibold uppercase text-green-900 antialiased align-middle">Keep</button>
                                </div>
                            </div>
                        );

                })}
            </div>
        }
    </div>

}
