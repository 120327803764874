export const showError = (error: any) => {
    alert(error);
}


export const unitDisplay = (input: any, unit: string) => {
    let num = +input;
    if (unit === 'cm') num = num * 2.54;
    else if (unit === 'in') num = num / 2.54;
    else if (unit === 'kg') num = num / 2.20462;
    else if (unit === 'lb') num = num * 2.20462;
    else throw new Error(`Bad unit ${unit}`);

    return Math.round(num * 100) / 100;
}