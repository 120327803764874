const apikey = 'K9EvZL8wXHYbGmkUDz7I';
const partnerid = '392';
const baseUrl = 'https://partner.uucargo.ca';
const api_quote = '/api/loose-item/3rd-party/rating';
const api_ltl_rate = '/api/shippingLTL/partner/LTLShippingRatingByServiceName';
const api_serviceNames = '/api/shippingLTL/partner/getAllAvailableServiceNames';

export type ContextPromise = Promise<Response> & {context?: any};

const post = ({ url, body, context }: any) => {
    const options: RequestInit = {
        headers: {
            apikey,
            partnerid,
            "Content-Type": 'application/json'
        },
        method: "POST",
        body: JSON.stringify(body)
    };
    const stub: ContextPromise = fetch(url, options);
    stub.context = context;
    return stub;
}

const get = ({ url, context }: any) => {
    const options: RequestInit = {
        headers: {
            apikey,
            partnerid,
            "Content-Type": 'application/json'
        }
    };
    const stub: ContextPromise = fetch(url, options);
    stub.context = context;
    return stub;
}

export const queryQuote = async(quoteReqInput: any, quoteAddon: any) => {
    const quoteReq = JSON.parse(JSON.stringify(quoteReqInput));
    const packageSpecialServices = quoteReq.rateData[0].packageData[0].packageSpecialServices;
    packageSpecialServices.signatureRequired = quoteAddon.signatureRequired;
    if (quoteAddon.hasBattery) {
        packageSpecialServices.batteryDetails = quoteAddon.batteryDetails;
    }
    const options: RequestInit = {
        headers: {
            apikey,
            partnerid,
            "Content-Type": 'application/json'
        },
        method: "POST",
        body: JSON.stringify(quoteReq)
    };
    const resp = await fetch(baseUrl + api_quote, options);
    const data = await resp.json();
    console.log('quote req and resp', quoteReq, data);
    return data;
};

export const queryServiceNames = () => {
    const url = baseUrl + api_serviceNames;
    return get({ url });
}

export const queryLtl = ({ quoteReq, quoteBattery, context }: any): ContextPromise => {
    const body = JSON.parse(JSON.stringify(quoteReq));
    body.serviceName = context.serviceName;

    if (quoteBattery.hasBattery) {
        const packageSpecialServices = { batteryDetails: quoteBattery.batteryDetails };
        body.packageData.forEach((element: any) => {
            element.packageSpecialServices = packageSpecialServices;
        });
    }

    const url = baseUrl + api_ltl_rate;
    return post({ url, body, context });
};

