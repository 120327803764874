'use client'

declare const google: any;

type AutoCompleteProps = React.ButtonHTMLAttributes<HTMLInputElement> & {
    inputId: string;
    onChanged: Function;
};

export type Address = { Street: string, PostalCode: string, City: string, Province: string, Country: string };

const parseGoogleAddress = (place: any) : Address => {
    const rs = { Street:' ', PostalCode:'', City:'', Province:'', Country:'' };
    if (place.address_components) place.address_components.forEach((addr: { types: string | string[]; short_name: string; }) => {
        addr.types.includes('street_number') && (rs.Street = addr.short_name + rs.Street);
        addr.types.includes('route') && (rs.Street += addr.short_name);
        addr.types.includes('postal_code') && (rs.PostalCode = addr.short_name);
        addr.types.includes('locality') && (rs.City = addr.short_name);
        addr.types.includes('administrative_area_level_1') && (rs.Province = addr.short_name);
        addr.types.includes('country') && (rs.Country = addr.short_name);
    });
    return rs;
}

import { useRef, useEffect } from "react";
// import "./styles.css";
const AutoComplete = ({ inputId, onChanged, ...props } : AutoCompleteProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const ini = () => {
        const options = {};
        const autoComplete = new google.maps.places.Autocomplete(
            inputRef.current,
            options
        );
        autoComplete.addListener("place_changed", async function () {
            const place = await autoComplete.getPlace();
            console.log(inputId, place);
            onChanged(parseGoogleAddress(place));
        });
    }

    useEffect(() => {
        if(Object.hasOwn(window, 'google') && google.maps && google.maps.places && google.maps.places.Autocomplete) {
            ini();
        } else {
            console.log('Wait for google script loading is done ..')
            document.addEventListener('googleApi', ini);
        }
    }, []);

    return (
        <div>
            <input id={inputId} ref={ inputRef } {...props} />
        </div>
    );
};
export default AutoComplete;